<template>
  <div class="content-favorites">
    <div class="content-favorites-itens container">
      <div class="content-favorites-itens-header">
        <h1>{{ $t('wishlist.wishlist') }}</h1>
      </div>
      <div class="content-favorites-itens-body">
        <div v-if="Object.keys(user).length == 0">
          <h2 class="not-wishlist">
            É necessário está logado para acessar a lista .
            <span>&#128533;</span>
          </h2>
        </div>
        <swiper-wishlist v-else />
      </div>
    </div>
  </div>
</template>

<script>
import SwiperWishlist from "./SwiperWishlist";
import { mapGetters } from "vuex";

export default {
  components: {
    SwiperWishlist,
  },
  computed: {
    ...mapGetters("User", ["user"]),
  },
};
</script>

<style lang="scss">
.content-favorites {
  padding: 80px 0px;
  @media (max-width: 991px) {
    padding: 40px 0px;
  }
  &-itens {
    &-header {
      padding-bottom: 10px;
      border-bottom: 1px solid #ebebeb;
      h1 {
        font-size: 35px;
        font-family: "Font Bold";
        color: var(--main-color5);
        margin-bottom: 0;
        text-transform: uppercase;
      }
    }
    &-body {
      padding-top: 50px;
    }
  }
}
</style>