<template>
  <swiper :options="SwiperWishlist">
    <template>
			<template v-if="wishlistProducts.length > 0">
				<template v-for="(wish, wishIndex) in wishlistProducts">
					<swiper-slide :key="wishIndex">
						<card-wishlist :wish="wish" :index="wishIndex"></card-wishlist>
					</swiper-slide>
				</template>
			</template>
			<template v-else>
				<h2 class="not-wishlist">
					{{ $t('wishlist.text_wishlist') }}
					<span> 
            <img class="img-fluid" src="@/assets/img/shopping-cart/image-cart.png" alt="image cart" title="image cart"/>
          </span>
				</h2>
    	</template>
    </template>
    <div
      class="swiper-pagination swiper-pagination-products"
      slot="pagination"
    ></div>
  </swiper>
</template>

<script>
import CardWishlist from "./CardWishlist";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { mapGetters } from "vuex";
import "swiper/css/swiper.css";

export default {
	metaInfo: {
    title: "Lista de desejos",
  },
  components: {
    Swiper,
    SwiperSlide,
    CardWishlist
  },
  directives: {
    swiper: directive,
  },
  computed: {
    ...mapGetters("User", ["user"]),
    ...mapGetters("Wishlist", ["wishlistProducts"]),
  },
  data() {
    return {
      SwiperWishlist: {
        slidesPerView: 1.6,
        spaceBetween: 20,
        speed: 800,
        watchOverflow: true,
        clickable: true,
        autoplay: {
          delay: 5000,
        },
        breakpoints: {
          768: {
            slidesPerView: 2.6,
          },
          992: {
            slidesPerView: 4,
          },
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
};
</script>

<style lang="scss">
.link-product {
  text-decoration: none !important;
}
.content-products-one {
  margin-bottom: 30px;
  &:hover,
  &:focus {
    .content-products-one-cards-image {
      transform: scale(1.03);
      opacity: 0.8;
    }
  }
  &-cards {
    text-align: left;
    &-image {
      transition: 500ms;
      overflow: hidden;
      img {
        max-width: 200px;
      }
    }
    &-text {
      padding-top: 20px;
      text-align: left;
      a {
        text-decoration: none !important;
      }
      .name {
        font-size: 25px;
        font-family: "Font Bold";
        color: var(--main-color5);
        margin-bottom: 25px;
        @media (max-width: 1199px){
					height: 90px;
				}
      }
      .subtitle {
				font-size: 20px;
				font-family: 'Font Bold';
				color: var(--main-color5);
				margin-bottom: 25px;
			}
      .quantity {
        display: flex;
        align-items: center;
        a {
          padding: 5px 20px;
          font-size: 13px;
          font-family: "Font Light";
          color: #707070 !important;
          border: 1px solid #e4e4e4;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 500ms;
          margin-right: 12px;
          &:last-child {
            margin-right: 0;
          }
          &:hover,
          &:focus {
            cursor: pointer;
            border: 1px solid #000;
          }
        }
        .active {
          border: 1px solid #000;
        }
      }
      .price {
        margin-top: 13px;
        display: flex;
        align-items: center;
        &-icon {
          height: 45px;
          width: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--main-color5);
        }
        &-value {
          margin-left: 15px;
          font-size: 30px;
          font-family: "Font Bold";
          color: var(--main-color4);
          margin-bottom: 0;
        }
      }
    }
  }
}
.swiper-pagination-products {
  bottom: 0 !important;
}
</style>